@import '../../helpers/style/variables';

.navbar {
  background-color: $fill-brand-blue-2;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 56px;
  background-color: $fill-brand-blue-2;
  color: $fill-bw-white;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: width 0.5s ease-in-out;
}

.sidebar > .linkContainer {
  margin-left: auto;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 24px;
  text-align: right;
  overflow: hidden;
  width: 300px;
}

.sidebar > .linkContainer > a {
  color: $fill-bw-white;
  text-decoration: none;
  margin-bottom: 16px;
  margin-right: 16px;
}

.sidebar.hidden {
  width: 0px;
  overflow: hidden;
}

.sidebar > .linkContainer > button {
  color: $fill-bw-white;
  width: 100%;
  font-size: 24px;
  text-align: right;
  margin-top: 16px;
  background-color: transparent;
  border: 0;
  margin-right: 16px;
  margin-left: auto;
}

.sidebar > .linkContainer > button > p {
  margin-right: 16px;
  margin-bottom: 0;
  pointer-events: none;
}

.buttons {
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.button {
  position: relative;
  padding: 0;
}

.buttons > button,
.buttons > .button > button {
  padding: 2px 6px !important;
}

.selected {
  position: absolute;
  width: 100%;
  height: 6px;
  top: -11px;
  background-color: $fill-bw-white;
  transition: all 0.5s ease-in-out;
}

.selected.hidden {
  height: 0;
}

.buttons > .localePicker {
  background-color: transparent;
  opacity: 0.4;
  padding: 2px 8px;
  margin-right: 14px;
  border: solid 1.5px $fill-bw-white;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: $fill-bw-white;
}

.buttons > div,
.buttons > div > button {
  background-color: transparent;
  border: 0;
}

.brand {
  padding-left: 16px;
  background-color: transparent;
  border: none;
}

.darkenBottom {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 98;
  top: 56px;
  background-color: transparentize($color: #000000, $amount: 0.3);
  transition: all 0.5s ease-in-out;
}

.darkenNavbar {
  position: fixed;
  width: 0;
  height: 56px;
  z-index: 99;
  top: 0px;
  background-color: transparentize($color: #000000, $amount: 0.3);
  transition: all 0.5s ease-in-out;
}

.darkenBottom.hidden,
.darkenNavbar.hidden {
  background-color: transparent;
  pointer-events: none;
}

svg {
  pointer-events: none;
}

@media (min-width: 470px) {
  .sidebar {
    width: 300px;
  }

  .darkenNavbar {
    width: calc(100% - 300px);
  }
}

.settingsButton{
  background-color: transparent;
  border: none;
}

.settingsButton svg path{
  fill: white;
}

.menuButton svg g{
  opacity: 1;
}
