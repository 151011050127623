.form_photo_upload {
    height: 16rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

.input_photo_upload {
    display: none;
}

.label_photo_upload {
    transition: 1s;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #ffffff;
}

.label_photo_upload.drag_active {
    transition: 1s;
    background-color: #a4c1de;
    color: white !important;
}

.upload_button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}

.upload_button:hover {
    text-decoration-line: underline;
}

.header_title {
    align-self: center;
    width: 100%;
    height: 22px;
    font-family: Barlow;
    font-size: 18px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--fill-brand-blue-7);
}