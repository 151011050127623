@import '../../helpers/style/variables';

.match_info_container {
  background-color: $fill-brand-blue-2;
}

.ext_matchClock {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $main-font-color-dark-bg;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $fill-brand-blue-8;
  width: 175px;
  min-height: 100%;
}

.matchClock {
  width: fit-content;
  min-height: fit-content;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $main-font-color-dark-bg;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $fill-brand-blue-8;
}

.alignCenter {
  text-align: center;
}

@media screen and (max-width: 850px) {
  .div_ex_clock {
    width: calc((100vw - 128px) / 4);
  }

  .ext_matchClock {
    width: 128px;
  }
}

.alignRightHelper {
  width: 20px;
}

.clockWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
}

.clockButton {
  background-color: #596c80;
  border: #596c80;
  font-size: 15px;
  width: 20px;
  border-radius: 0%;
  padding: 0;
}