.eventsContainer {
  margin: 0px;
  padding: 0px;
}

.hidden {
  opacity: 0.5;
}

.iconsRow {
  position: relative;
  margin: 0px 2px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.categoryDescr {
  margin-left: 5px;
  margin-right: 25px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.categoryDescr > span {
  opacity: 0.5;
  font-weight: bold;
}

.categoryImg {
  width: 32px;
  height: 32px;
  margin-left: 16px;
}

.iconsRow > * {
  cursor: pointer;
}

.clockBox {
  text-align: right;
  margin-right: 1.3rem;
  margin-left: auto;
  display: flex;
}

.clockIcon {
  cursor: pointer;
}

.clock {
  font-weight: bold;
  font-stretch: condensed;
  color: #596c80;
  width: 50px;
}

.clockRow {
  height: 157px;
  margin: 0px 20px;
  margin-top: 0.95rem;
}

.clockHalfs {
  width: 50%;
  height: 100%;
}

.clockNormalHalfs {
  float: left;
  width: 50%;
  height: 120px;
  margin-top: 20px;
}

.clockOverTimeHalfs {
  float: right;
  width: 50%;
  height: 120px;
  margin-top: 20px;
}

.clockInput {
  width: 80%;
  position: relative;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  margin: auto;
}

.clockDisplay {
  font-size: 88px;
  color: #3e5368;
  height: 70%;
  width: 100%;
}

.clockButton {
  background-color: #596c80;
  border: #596c80;
  margin: auto .3rem auto .5rem;
  font-size: 20px;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
}

.clockOutText{
  height: 7%;
  margin-top: 0;
  font-size: 13px;
}

.clockInputSub{
  width: 50%;
}

.clockCircle {
  cursor: pointer;
  position: absolute;
  right: 5%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border: solid 1px #728293;
  border-radius: 50%;
}

.clockCircle:nth-child(2) {
  top: 55px;
}

.clockCircle:nth-child(3) {
  top: 100px;
}

.clockElipse {
  cursor: pointer;
  position: relative;
  width: 45px;
  height: 30px;
  border: solid 1px #728293;
  border-radius: 50%;
  margin: 0 auto;
}

.clockElipse:first-child {
  top: 15%;
}

.clockElipse:nth-child(2) {
  top: 35%;
}

.selectedHalf {
  color: #ffffff;
  background-color: #596c80;
}

@media screen and (max-width: 700px) {
  .clockDisplay {
    font-size: 44px;
  }
}

@media screen and (max-width: 450px) {
  .categoryImg {
    margin-left: 0px;
  }
  .clockDisplay {
    font-size: 44px;
  }
}

@media screen and (max-width: 400px) {
  .clockDisplay {
    font-size: 33px;
  }
}

.insideClockCircle {
  font-size: 14px;
  color: #3e5368;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.insideClockElipse {
  font-size: 14px;
  font-weight: bold;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eventsList {
  display: block;
  position: static;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 128px;
  margin: 0px 5px;
}

.eventCard {
  display: inline-block;
  float: none;
  padding: 0px;
  // margin: 0px 20px;
  width: 64px;
}

.eventCard:not(:first-child) {
  margin-left: 20px !important;
}

.cardImage {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  transform: translate(-50%, -50%);
}

.circle {
  position: relative;
  width: 64px;
  height: 64px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.circle.selected {
  border: 5px solid #596c80;
}

.eventName {
  width: 64px;
  height: 64px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  white-space: initial;
  font-size: 12px;
  font-stretch: condensed;
  color: #3e5368;
}

.breadCrumbs {
  display: flex;
  width: 65%;
  cursor: default;
  // overflow-x: auto;
  // overflow-y: hidden;
  align-items: center;
  text-overflow: clip;
  white-space: nowrap;
  align-items: center;
  position: relative;
}

.breadCrumb {
  cursor: pointer;
  height: 22px;
  border-radius: 14px;
  background-color: #a5afb9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadCrumb:not(:first-child) {
  margin-left: 5px;
}

.breadCrumb > span {
  margin: 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #596c80;
  opacity: 1;
  line-height: 1.17;
}

.inner {
  // position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.inner::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Hide scrollbar for IE and Edge */
.inner {
  -ms-overflow-style: none;
}

.fade {
  background: -moz-linear-gradient(
    90deg,
    rgba(242, 242, 242, 0) 70%,
    rgba(242, 242, 242, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    90deg,
    rgba(242, 242, 242, 0) 70%,
    rgba(242, 242, 242, 1) 100%
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(242, 242, 242, 0) 70%,
    rgba(242, 242, 242, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    90deg,
    rgba(242, 242, 242, 0) 70%,
    rgba(242, 242, 242, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(242, 242, 242, 0) 70%,
    rgba(242, 242, 242, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    90deg,
    rgba(242, 242, 242, 0) 70%,
    rgba(242, 242, 242, 1) 100%
  );
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ignoreMinuteContainer {
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  margin-left: 5px;
}

.favouritesStar {
  position: absolute;
  right: -10px;
  z-index: 1;
  pointer-events: all;
}

.favouritesStar:hover {
  cursor: pointer;
}

.favourite {
  color: #ff5f00;
}

.favouritesImg {
  margin-right: 16px;
  pointer-events: all;
}

.uniform {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uniform svg {
  width: 100%;
  height: 100%;
}