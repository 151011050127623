// Theme colors

$main-blue: #3399cc;
$main-orange: #ff5f00;
$main-background-light: #fafafa;
$main-background-dark: #2f3e4f;
$main-font-color-light-bg: #3e5368;
$main-font-color-dark-bg: #ffffff;
$placeholder-font-color: #868686;
$placeholder-bg-color: #c7c7c7;
$main-background-color: #3e5368;
$main-success: #7bba5d;
$main-warning: #ffe249;
$main-danger: #d14545;
$secondary-background-color: #ffffff;

$fill-brand-blue-4: #3399cc;
$dark-grey-blue: #2c3e4f;
$fill-brand-blue-3: #273442;
$fill-brand-blue-2: #23323f;
$fill-brand-blue-5: #3abbfc;
$sickly-yellow: #dde738;
$fill-brand-blue-9: #aab6c2;
$fill-brand-blue-1: #3e5368;
$fill-brand-blue-8: #818e9b;
$fill-brand-blue-7: #596c80;
$lemon: #fff251;
$fill-tone-01: #ff5f00;
$fill-brand-blue-6: #548bb6;
$cell-tab-bet: #f04e23;
$cell-bg-bet: #f5ebdc;
$fill-bw-gray-light-02: #e9e9e9;
$fill-bw-gray-light-01: #f2f2f2;
$fill-bw-gray-light-04: #cccccc;
$fill-bw-gray-dark-02: #666666;
$fill-bw-gray-light-03: #dddddd;
$fill-bw-gray-light-00: #fafafa;
$fill-bw-gray-dark-03: #888888;
$fill-bw-gray-dark-01: #333333;
$fill-action-notification: #fb6002;
$fill-action-warning-2: #d5b408;
$fill-action-validate: #7fc97d;
$fill-action-errormessage: #fbcaca;
$fill-action-error: #cd323f;
$fill-bw-white: #ffffff;
$fill-action-warning: #decf20;
$fill-bw-black: #000000;
