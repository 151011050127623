@import '../../helpers/style/variables';

.noButton {
    color: #3e5368;
    border-color: #3e5368;
    background: none;
}

.noButton:hover {
    background: #3e5368 !important;
    border-color: #3e5368 !important;
}

.noButton:active {
    background: #36495c !important;
    border-color: #36495c !important;
}