.modal {
  display:flex;
}

.modal > div {
  margin-top: 3.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.modal > div > div {
  border-radius: 10px;
}

.clockCircle {
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border: solid 1px #728293;
  border-radius: 50%;
}

.clockRow {
  margin-right: auto !important;
  height: 64px;
  margin: 0px 5px;
}

.clockDisplay {
  font-family: Barlow;
  font-weight: bold;
  font-size: 32px;
  color: #3e5368;
  margin: 6px 25px 0px 25px;
}

.insideClockCircle {
  font-family: Barlow;
  font-size: 14px;
  color: #3e5368;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clock {
  margin-left: -15px;
  font-weight: bold;
  color: #596c80;
}

.type_edit {
  padding: 0rem 0.1rem;
  border: 0 !important;
  color: #728293 !important;
  border-radius: 0px 0px 0px 0px !important;
  overflow-y: auto;
  background-color: #ffffff !important;
  height: 115px !important;
  font-family: Barlow;
  font-size: 16px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: var(--fill-brand-blue-7);
}

.type_edit:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.header_icon {
  margin-left: auto;
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.delete {
  margin-right: 20px;
}

.header_title {
  align-self: center;
  width: 100%;
  height: 22px;
  font-family: Barlow;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--fill-brand-blue-7);
}
.cursor_on {
  cursor: pointer;
}
.save_btn {
  background-color: #3e5368;
  border-color: #3e5368;
  width: 130px;
  height: 30px;
  border-radius: 10px;
  font-family: Barlow;
  font-weight: bold;
  padding: 0px;
}

.save_btn:hover {
  background-color: #2e3944;
  border-color: #2e3944;
}

.close_btn {
  margin-left: initial;
}

.confirmDelete {
  height: 160px;
  margin-left: 10px;
  margin-right: 10px;  
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmDelete > span {
  font-size: 18px;
  white-space: pre-line;
  text-align: center;
}

.confirmDeleteFooter {
  justify-content: space-around;
}

.cancelButton,
.cancelButton:hover,
.cancelButton:active {
  color: #3e5368;
  border-color: #3e5368;
  background: none;
  width: 40%;
  font-size: 18px;
}

.deleteButton {
  text-emphasis: bold;
  width: 40%;
  font-size: 18px;
  border-radius: 10px;
}