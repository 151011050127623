@import '../../helpers/style/variables';

.cursor_on {
    cursor: pointer;
}

.header_icon {
    margin-left: auto;
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.header_title {
    align-self: center;
    width: 100%;
    height: 22px;
    font-family: Barlow;
    font-size: 18px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--fill-brand-blue-7);
}

.type_edit {
    border-color: #3e5368;
}

.type_edit:focus {
    box-shadow: none !important;
    outline: 0px !important;
}