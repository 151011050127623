.notScrollable {
    touch-action: none;
}

.playerCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 17%;
    z-index: 300;
    position: absolute;
    text-align: center;
    cursor: move;
    will-change: transform;
    border-radius: 5px;
    font-family: 'saira condensed', Arial, Helvetica, sans-serif;
    font-weight: 500;
    transition: opacity 0.3s ease-in-out;
    user-select: none;
  }
  
  .playerCard .portrait {
    border-bottom: 2px solid white;
    user-select: none;
    background-size: cover;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: 2px 2px 5px #23323f;
  }
  
  .playerCard p {
    position: absolute;
    top: 65px;
    width: 100%;
    margin: 0;
    padding: 0.1rem;
    user-select: none;
    background-color: #EFF2F6;
    border-radius: 4px;
    font-family: Barlow, Padding;
    font-size: 12px;
    font-weight: bold;
    color: #363F4E;
    box-shadow: 0px 0px 10px #23323f;
  }
  
  @media (max-width: 990px){
    .playerCard .portrait {
      width: 90px;
      height: 90px;
    }
    .playerCard p {
      font-size: 13px;
      top: 70px;
    }
  }
  
  @media (max-width: 550px){
    .playerCard .portrait {
      width: 50px;
      height: 50px;
    }
    .playerCard p {
      font-size: 10px;
      top: 35px;
    }
  }