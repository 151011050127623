@import '../../helpers/style/variables';

/*
* PLAYER GROUP
*/

.playerGroupWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  width: 310px;
}

.playerGroupHeader {
  border-radius: 8px 8px 0 0;
  color: $main-font-color-dark-bg;
  padding: 8px 16px;
  display: flex;
}

.playerGroupHeader :first-child {
  margin-right: auto;
  font-weight: 700;
}

.playerGroupHeader > p {
  margin-bottom: 0;
}

.playerGroupHeader-main {
  background-color: $main-blue;
}

.playerGroupHeader-substitutes {
  background-color: $main-orange;
}

.playerGroupHeader-all {
  background-color: #596c80;
}

.playerGroupInfoWrapper {
  padding: 12px 16px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .playerGroupWrapper:not(:first-child) {
    margin-left: 16px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .playerGroupWrapper:first-child {
    margin: 0;
  }
}
