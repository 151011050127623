@import '../../helpers/style/variables';

/*
* PLAYER INFO
*/

// Wrapper

.playerInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.playerInfoWrapper> :not(:first-child) {
  margin-left: 8px;
}

// Number

.playerInfoNumber {
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end;
  text-align: end;
}

.playerInfoNumber>p {
  text-align: end;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

// Photo

.playerInfoPhotoWrapper {
  flex-basis: 10%;
}

.playerInfoPhoto {
  height: 36px;
  width: 36px;
  background-size: cover;
  border-radius: 50%;
}

// Name

.playerInfoName {
  flex-basis: 50%;
}

.playerInfoName>p {
  margin: 0;
  font-size: 14px;
}

// Buttons

.playerInfoButtons {
  flex-basis: 30%;
  display: flex;
  justify-content: center;
}

.playerInfoButtons>button {
  width: 28px;
  height: 28px;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  color: rgba(62, 83, 104, 0.5);
  border: 1px solid rgba(62, 83, 104, 0.5);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0;
}

.playerInfoButtons>button:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(62, 83, 104, 0.5);
}

.playerInfoButtons :not(:first-child) {
  margin-left: 6px;
}

.playerInfoButtons>button.playerInfoButtonsSelectedMain,
.playerInfoButtons>button.playerInfoButtonsSelectedSub,
.playerInfoButtons>button.playerInfoButtonsSelectedAll {
  color: $main-font-color-dark-bg;
}

.playerInfoButtons>button.playerInfoButtonsSelectedMain {
  border-color: $main-blue;
  background-color: $main-blue;
}

.playerInfoButtons>button.playerInfoButtonsSelectedSub {
  border-color: $main-orange;
  background-color: $main-orange;
}

.playerInfoButtons>button.playerInfoButtonsSelectedAll {
  border-color: #596c80;
  background-color: #596c80;
}

@media (hover: hover) {
  .playerInfoButtons>button.playerInfoButtonsMain:hover,
  .playerInfoButtons>button.playerInfoButtonsSub:hover,
  .playerInfoButtons>button.playerInfoButtonsAll:hover,
  .playerInfoButtons>button.playerInfoButtonsAdd:hover {
    color: $main-font-color-dark-bg;
  }

  .playerInfoButtons>button.playerInfoButtonsMain:hover {
    border-color: $main-blue;
    background-color: $main-blue;
  }

  .playerInfoButtons>button.playerInfoButtonsSub:hover {
    border-color: $main-orange;
    background-color: $main-orange;
  }

  .playerInfoButtons>button.playerInfoButtonsAll:hover {
    border-color: #596c80;
    background-color: #596c80;
  }

  .playerInfoButtons>button.playerInfoButtonsAdd:hover {
    border-color: #57B578;
    background-color: #57B578;
  }
}

// Flag and position

.playerInfoFlagPosition {
  display: flex;
  align-items: center;
}

.playerInfoFlagPosition>img {
  border-radius: 15%;
  max-height: 14px;
  overflow: hidden;
}

.playerInfoFlagPosition>* {
  display: inline;
}

.playerInfoFlagPosition>p {
  font-size: 14px;
  font-weight: 400;
}

.playerInfoFlagPosition>p {
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.placeholder {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgb(185, 185, 185);
}

// Progress bar

.buttonsAndProgressWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBar {
  width: 100%;
  height: 4px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  background-color: $fill-bw-gray-light-02;
}

.mainPercent,
.benchPercent,
.allPercent {
  height: 100%;
}

.mainPercent {
  background-color: $fill-brand-blue-6;
}

.benchPercent {
  background-color: $fill-tone-01;
}

svg.icon {
  pointer-events: all;
}

.icon:hover {
  cursor: pointer !important;
}