@import '../../../helpers/style/variables';

.matchListItemWrapper {
  padding: 12px 16px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.nameAndDate {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  padding-right: 4px;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.results {
  flex-basis: 20%;
  align-items: center;
}

.arrow  {
  flex-basis: 10%;
}

.results > p,
.nameAndDate > p {
  margin: 0;
}

.goals,
.nameAndDate > p:first-child {
  font-size: 16px;
  color: $fill-tone-01;
  font-weight: bold;
}

.time,
.nameAndDate > p:not(:first-child) {
  font-size: 12px;
  color: $fill-brand-blue-1;
}

.arrow {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.ongoing {
  color: $fill-tone-01 !important;
}

.other {
  color: $fill-brand-blue-1 !important;
}
