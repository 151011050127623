@import '../../../helpers/style/variables';

.matchListWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.matchListWrapper {
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  width: 310px;
}

.matchListHeader {
  height: 40px;
  background-color: $fill-brand-blue-8;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.matchListHeader > p {
  font-size: 18px;
  font-weight: bold;
  color: $fill-bw-white;
  margin-bottom: 0;
  margin-left: 16px;
}

.placeholder {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: $fill-brand-blue-1;
}

.placeholder > p {
  color: $fill-brand-blue-1;
  opacity: 0.5;
  margin: 0;
}

@media (min-width: 992px) {
  .matchListWrapper:not(:first-child) {
    margin-left: 16px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .matchListWrapper:first-child {
    margin: 0;
  }
}
