@import '../../helpers/style/variables';

.match_info_container {
  background-color: $fill-brand-blue-2;
}

.div_ex_clock {
  width: 169px;
  max-height: 20px;
  background-color: $fill-brand-blue-8;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  border: 0;
  color: #131c23;
}

.scores_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 9px;
  margin-top: 7px;
}

.colRight {
  padding-right: 0;
  padding-left: 0;
}

.colLeft {
  padding-left: 0;
  padding-right: 0;
}

.matchScores {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $main-font-color-dark-bg;
  min-width: 20px;
}

.scores_container>p {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: $main-font-color-dark-bg;
}

.teamRight {
  font-weight: bold;
  color: white;
}

.team_img_left {
  height: inherit;
}

.team_img_right {
  height: inherit;
}

.left_team_name {
  font-size: 24px;
  margin-bottom: 0rem;
  margin-left: 6px;
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $main-font-color-dark-bg;
}

.right_team_name {
  font-size: 24px;
  margin-bottom: 0rem;
  margin-right: 6px;
  float: right;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $main-font-color-dark-bg;
}

@media screen and (max-width: 550px) {
  .left_team_name {
    font-size: 20px;
  }
  .right_team_name {
    font-size: 20px;
  }
  .team_name_invisible {
    display: none;
  }
}

.flex_container_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 169px;
}

.flex_container_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 169px;
}

.imgRight {
  text-align: center;
  justify-self: center;
  width: 32px;
  height: 32px;
  margin-left: 4px;
}

.imgLeft {
  text-align: center;
  justify-self: center;
  width: 32px;
  height: 32px;
  margin-right: 4px;
}

.alignCenter {
  text-align: center;
}

.circle_icon {
  width: 12px !important;
  height: 12px;
  color: white;
  vertical-align: middle;
  margin-left: 14px;
  margin-right: 14px;
}

.left_side {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.center_side {
  display: flex;
  flex-direction: column;
  //  align-content: stretch;
}

.right_side {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.row_clock {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: center;
  min-height: 32px;
}

.row_info {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 0px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.game_state {
  margin-top: -8px;
  margin-bottom: 0;
  color: $main-orange
}

@media screen and (max-width: 850px) {
  .div_ex_clock {
    width: calc((100vw - 128px) / 4);
  }
}

@media screen and (max-width: 450px) {
  .circle_icon {
    width: 12px !important;
    height: 12px;
    vertical-align: middle;
    margin-left: 0.15rem;
    margin-right: 0.15rem;
  }

  .imgRight {
    width: 24px;
    height: 24px;
  }

  .imgLeft {
    width: 24px;
    height: 24px;
  }
}

.headerWrapper {
  background-color: $fill-brand-blue-2;
  // position: fixed;
  width: 100%;
  z-index: 1;
}