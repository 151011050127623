@import '../../helpers/style/variables';

.header {
  height: 65px;
  background-color: transparentize($fill-brand-blue-9, 0.8);
  display: flex;
  align-items: center;
}

.header > p {
  margin-left: 40px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}

.mainContentWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .mainContentWrapper {
    flex-direction: row;
  }
}
