@import '../../helpers/style/variables';

/* width */
::-webkit-scrollbar {
  width: 10px;
}
.exterior_container {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  padding-right: 0px;
  padding-left: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card {
  border-radius: 0px !important;
  overflow-y: auto;
}

.msg_card_body {
  overflow-y: auto;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  padding-right: 2.5rem !important;
  padding-left: 0 !important;
}

.cmts_container {
  padding-left: 0;
  padding-right: 0;
  background-color: $main-background-light;
  margin-top: 0px;
}

.eventsOpen {
  margin-bottom: 273px;
}

.confirmModal {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.modal {
  display:flex;
}

.modal > div {
  margin-top: 3.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.modal > div > div {
  border-radius: 10px;
}

.header_title {
  align-self: center;
  width: 100%;
  height: 22px;
  font-family: Barlow;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--fill-brand-blue-7);
}
