.pitch {
    position: relative;
    width: 100%;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.06);
    perspective: 800px;
}

.pitch>div {
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 5%, rgba(255, 255, 255, 0) 100%);
}

.pitch .outlines {
    display: block;
    pointer-events: none;
    width: 100%;
    user-select: none;
    border-radius: .3rem;
}

.pitchWrapper {
    margin-top: 24px;
}

.pitchGroupWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}