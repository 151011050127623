@import '../../helpers/style/variables';

.match {
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.divider {
  border-width: 1px;
  margin-left: -15px;
  margin-right: -15px;
  opacity: 0.8;
  background-color: #e2e7ed;
}

.infos{
  display: flex;
  flex-direction: column;
}

.infos > p {
  margin-bottom: 0;
}

.matchInfo,
.result {
  height: 22px;
  opacity: 0.8;
  font-family: Barlow;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $fill-brand-blue-1;
}


.matchDateInfo {
  height: 18px;
  opacity: 0.8;
  font-family: Barlow;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $fill-brand-blue-1;
  margin: 0;
}

.arrow {
  flex-basis: 10%;
  background: none;
  background-color: transparent;
  border: 0;
  padding: 0;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 100%;
}

.inGame {
  color: $main_orange;
}

.gameTime {
  text-align: end;
  font-size: 12px;

}
