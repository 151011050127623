@import './variables';

.form-control {
  background-color: transparent !important;
  color: $main-font-color-light-bg !important;
  border-radius: 1em !important;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  transition: background-color 999999s ease-in-out 0s;
  -webkit-text-fill-color: $main-font-color-light-bg !important;
  caret-color: $main-font-color-light-bg !important;
}
