@import '../../helpers/style/variables';


.login {
  height: 100%;
  background-color: $main-background-color;
  color: $main-font-color-dark-bg;
}

.bg {
  min-height: 100vh;
  background: url('../../assets/login-bg.jpg') no-repeat center center fixed;
  background-size: cover;
}

.logo {
  position: absolute;
  margin-top: 16px;
  margin-left: 16px;
}

.title {
  font-size: 32px;
  font-family: Barlow;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
}

.error {
  font-family: Barlow;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $fill-tone-01;
  height: 20px;
  margin-bottom: 20px;
}

.form {
  max-width: 305px;
}

.link {
  font-family: Barlow;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $fill-bw-white;
}

.submit {
  width: 163px;
  border-radius: 8px;
  margin-top: 2rem;

  color: $fill-bw-white;
  background-color: $fill-brand-blue-7;
  border-color: $fill-brand-blue-7;

  font-family: Barlow;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.submit:hover {
  background-color: $fill-brand-blue-7;
  border-color: $fill-brand-blue-7;
}

.login>*,
.fullHeight {
  min-height: 100vh;
}

input.inputBox {
  background-color: $fill-bw-white !important;
  border-radius: 8px !important;
  border: 2px solid #999999 !important;
  font-size: 17px;
}

.password>div {
  margin-bottom: 8px;
}

.twitterButton,
.twitterButton:hover,
.twitterButton:active,
.twitterButton:focus {
  background-color: #3abbfc !important;
  border-color: #3abbfc !important;
  width: 295px;
}

.zzSeparatorText {
  text-align: center;
  font-size: 18px;
  position: relative;
  width: 20%;
}

.zzSeparatorTextLeft {
  margin: 0 15px 15px auto;
}

.zzSeparatorTextRight {
  margin: 0 auto 15px 15px;
}

.zzSeparatorText:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-bottom: 1px solid #dddddd;
  border-bottom-color: rgba(255, 255, 255, 0.22);
  z-index: 0;
}

.zzSeparatorTextSpan {
  margin: 0 0 15px 0;
}

@media (max-width: 350px) {
  .title {
    display: none;
  }
}

@media (max-height: 600px) {
  .title {
    display: none;
  }
  .error {
    height: 36px;
  }
}

.zzButton {
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto;
}

.zzButton img {
  margin-right: 15px;
}