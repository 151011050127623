@import '../../helpers/style/variables';

.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 320px;
  bottom: 0;
  right: calc(50% - 160px);
  padding: 8px 8px 16px 8px;
  border-radius: 8px;
  background-color: transparent;
}

.hidden {
  display: none;
}

.container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 2px 0;
}

.container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  padding: 2px 0;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  padding: 2px 0;
  background-color: rgb(131, 131, 131);
}

@media (min-width: 565px) {
  .container {
    right: 16px;
  }
}
