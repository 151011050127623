@import '../../helpers/style/variables';

.autocomplete {
  width: 450px;
  height: 40px;
  margin-top: 12px;
  margin-bottom: 12px;

  &::placeholder {
    color: $placeholder-font-color;
    margin-bottom: 100px;
  }
}

@media (max-width: 765px) {
  .autocomplete {
    width: 100%;
  }


}

.autocomplete > input {
  opacity: 0.5;
  border-radius: 20px !important;
  border: solid 20px #999999 !important;
  margin-top: 12px;
  margin-bottom: 12px;

  &::placeholder {
    color: $placeholder-font-color;
    margin-bottom: 100px;
  }
}
