@import '../../../helpers/style/variables';

.container {
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background-color: #ffffff;
  padding: 12px 18px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  transition: display 0.3s linear;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title > button > svg {
  width: 32px !important;
  height: 32px;
}

.title > span {
  margin-right: auto;
  font-size: 16px;
  font-weight: bold;
}

.hidden {
  display: none;
}

.dismiss {
  background-color: transparent;
  color: $main-font-color-light-bg;
  border: none;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
}

@media (min-width: 992px) {
  .container {
    width: 310px;
    left: calc(50% - 155px);
  }
}
