@import '../../helpers/style/variables';

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 0 16px;
  background-color: $fill-brand-blue-2;
  color: $main-font-color-dark-bg;
}

.contentWrapper > div {
  flex: 1 1 0;
}

.team {
  display: flex;
  align-items: center;
}

.team:first-child {
  justify-content: flex-start;
}

.team:not(:first-child) {
  justify-content: flex-end;
}

.team > p,
.versus > p {
  margin: 0;
  text-align: center;
}

.versus .score {
  font-size: 36px;
  font-weight: bold;
}

.versus .edition {
  font-size: 15px;
}

.team > p {
  font-size: 24px;
  font-weight: bold;
}

.team > p:first-child {
  margin-right: 8px;
}

.team > p:not(:first-child) {
  margin-left: 8px;
}

.teamLogo {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

@media (min-width: 992px) {
  .contentWrapper {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .team:first-child,
  .team:not(:first-child) {
    justify-content: center;
  }
}


@media screen and (max-width: 550px) {
  .team > p {
    font-size: 20px;
  }
  .team > p.invisible {
    display: none;
  }
}
