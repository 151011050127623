@import '../../helpers/style/variables';

.panel {
    margin: auto;
    max-width: 851px;
}

.card {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    background-color: #818e9b;
    margin-bottom: 0 !important;
}

.cardHome {
    border-right: solid 1px !important;
    transition-duration: 1s;
}

.cardAway {
    border-left: solid 1px !important;
    transition-duration: 1s;
}

.cardHomeHover:hover, .cardAwayHover:hover {
    background-color: #556370;
    border-color: #556370;
    cursor: pointer;
    transition-duration: 1s;
}

.cardBody {
    padding: 1rem;
}

.penalties {
    align-items: center;
    justify-content: center;
}

.penaltyCircle {
    width: 30px;
    height: 30px;
    margin: auto 1rem;
    background-color: #fafafa !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.penaltyCircleDisabled {
    width: 30px;
    height: 30px;
    margin: auto 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.penaltyCircle > p {
    margin: 0;
    color: #23323f;
}

.penaltyCircle .player_name {
    display: none;
}

.penaltyCircle:hover .player_name {
    display: block;
    background-color: #fff;
    position: absolute;
    top: -1rem;
    box-shadow: 0px -2px 5px #2c3e50;
    border-radius: .5rem;
    padding: .25rem;
}

@media screen and (max-width: 850px) {
    .panel {
        margin: auto;
        max-width: 100%;
    }

    .penaltyCircle, .penaltyCircleDisabled {
        width: 25px;
        height: 25px;
        margin: auto .5rem;
    }
}

@media screen and (max-width: 500px) {
    .penaltyCircle, .penaltyCircleDisabled {
        width: 20px;
        height: 20px;
        margin: auto .1rem;
    }
}

@media (min-width: 0px) {
    .panel {
        display: flex !important;
        flex-flow: row wrap !important;
    }

    .card {
        flex: 1 0;
    }
}