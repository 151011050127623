@import '../../helpers/style/variables';

.matchWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  flex-direction: row-reverse;
}

@media (min-width: 992px) {
  .matchWrapper {
    max-width: 960px;
  }
}

.headerWrapper {
  background-color: $fill-brand-blue-2;
}

.autofillBtn {
  background-color: #3399cc;
  border-color: #3399cc;
}

.autofillBtn:hover, .autofillBtn:active, .autofillBtn:focus {
  background-color: #0f84be !important;
  border-color: #3399cc !important;
}

.resetBtn {
  background-color: #596c80;
  border-color: #596c80;
}

.resetBtn:hover, .resetBtn:active, .resetBtn:focus {
  background-color: #aab6c2 !important;
  border-color: #596c80 !important;
}