@import '../../helpers/style/variables';

.light {
  background-color: $main-background-light;
  color: $main-font-color-light-bg;
}

.dark {
  background-color: $main-background-color;
  color: $main-font-color-dark-bg;
}

.pageTitle {
  margin-bottom: 1em;
}

.mainContentWrapper {
  margin-top: 56px;
}
