@import '../../helpers/style/variables';

.modal {
  display:flex;
}

.modal > div {
  margin-top: 3.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.modal > div > div {
  border-radius: 10px;
}

.header_title {
  align-self: center;
  width: 100%;
  height: 22px;
  font-family: Barlow;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--fill-brand-blue-7);
}

.cmt_icon_container {
  padding-bottom: 1rem;
}

.event_icon {
  width: 32px;
  height: 32px;
  padding: 4px;
  margin: 0px !important;
  color: $fill-brand-blue-9 !important;
}