@import '../../helpers/style/variables';

.msg_card_body {
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0 !important;
  padding-bottom: 2.7rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.col_min_time {
  flex-grow: 0;
  min-width: 24px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
  margin-top: 23px;
  justify-content: initial;
  align-items: center;
  min-width: fit-content;
}

.col_user_comments {
  flex-grow: 0;
  width: 4px;
  margin-right: 8px;
}

.col_other_user_comments {
  flex-grow: 0;
  width: 4px;
}

.col_cmt_icon {
  flex-grow: 0;
  min-width: fit-content;
  padding: 0;
  margin-top: 15px;
  margin-left: 6px;
  margin-bottom: 36px;
}

.col_options_icon {
  flex-grow: 0;
  min-width: 32px;
  padding: 0;
  margin-top: 23px;
  margin-left: 4px;
  margin-bottom: 36px;
  visibility: hidden;
}

.col_cmt_text {
  flex-grow: 1;
  margin-top: 23px;
  padding-left: 0;
}

.event_icon {
  height: inherit;
  width: inherit;
  padding: 4px;
  margin: 0px !important;
  color: $fill-brand-blue-9 !important;
}

.icon_container {
  display: flex;
  align-items: center;
}

.comment_text_box {
  margin-top: 0;
  margin-bottom: 23px;
  white-space: pre-wrap;
  margin-left: 7px;
  position: relative;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: $fill-brand-blue-7;
}

.cmt_time,
.cmt_current_score {
  float: right;
  opacity: 0.5;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  width: fit-content;
  text-align: right;
  color: $fill-brand-blue-7;
  min-width: 24px;
}

.cmt_current_score {
  background-color: #DFE4EC;
  padding: 4px;
  border-radius: 4px;
  margin-top: 5px;
}

.row_comments {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 84px !important;
  background-color: $main-background-light;
}

.arrowBtns {
  position: absolute;
  left: 50%;
  z-index: 99;
  background: none;
  border: none;
  padding: .1rem 1rem;
  color: #3e5368;
  visibility: hidden;
}

.arrowBtns:hover, .arrowBtns:active, .arrowBtns:focus {
  box-shadow: none;
  background: none !important;
  color: #3e5368 !important;
}

.upArrowBtn {
  top: 0rem;
  box-shadow: 0px -5px 1px rgba(0, 0, 0, 0.438);
}

.downArrowBtn {
  bottom: 0rem;
  box-shadow: 0px 5px 1px rgba(0, 0, 0, 0.438);
}

.row_comments:hover .col_options_icon {
  visibility: visible;
}

.hoverDiv:hover .arrowBtns {
  visibility: visible;
}

.row_comments:hover {
  background-color: $secondary-background-color;
}

.row_matchFinished {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.span_matchFinished {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 14px;
  background-color: $fill-brand-blue-7;
  color: $main-font-color-dark-bg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  height: 22px;
  display: flex;
  align-items: center;
}

.cmt_time_extra {
  float: right;
  opacity: 0.5;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: $fill-brand-blue-7;
  width: fit-content;
  padding-right: 3px;
}

.options_icons {
  margin: 10px;
  display: flex;
  align-items: center;
}

.cmt_icon_container {
  width: 32px;
  height: 32px;
}

.opt_icon_container {
  width: 24px;
  height: 24px;
}

.circle_icon {
  font-size: 1.5em;
  margin-left: auto;
}

.cursor_on {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .col_options_icon {
    visibility: visible !important;
  }
}

.noWidth {
  flex-grow: 0;
}

.imageModal {
  background-color: #23323fd8;
}