@import '../../helpers/style/variables';

.teamPickerWrapper {
  background-color: $fill-brand-blue-2;
  display: flex;
}

.teamPickerWrapper > * {
  flex-grow: 1;
  flex-basis: 33%;
}

.teamPickerWrapper > a {
  text-decoration: none;
}

.teamPickerWrapper > button,
.teamPickerWrapper > a {
  background-color: transparentize($fill-brand-blue-8, 0.4);
  color: $fill-bw-white;
  border: 0;
  transition: all 0.3s ease-in-out;
  text-align: center;
  padding: 6px 0;
}

.teamPickerWrapper > button:focus,
.teamPickerWrapper > a:focus {
  outline: 0;
}

.teamPickerWrapper > button:hover,
.teamPickerWrapper > a:hover {
  background-color: $fill-brand-blue-9;
}

.teamPickerWrapper > button > p,
.teamPickerWrapper > a > p {
  font-size: 24px;
  font-weight: bold;
  opacity: 0.5;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.teamPickerWrapper > button:hover,
.teamPickerWrapper > button:hover > p,
.teamPickerWrapper > a:hover,
.teamPickerWrapper > a:hover > p,
.teamPickerWrapper > button.teamPickerSelected > svg {
  opacity: 1;
}

.teamPickerWrapper > button > svg {
  pointer-events: none;
  opacity: 1;
}

.teamPickerWrapper > button.teamPickerSelected {
  background-color: $fill-brand-blue-9;
  transition: all 0.3s ease-in-out;
}

.teamPickerWrapper > .date > p {
  font-size: 16px;
}

.teamPickerWrapper > .date {
  border-radius: 0%;
}

@media (min-width: 992px) {
  .teamPickerWrapper {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}
