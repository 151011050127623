.form-control {
  width: 100%;
  border-radius: 8px;
  border: solid 2px #999999;
  height: 100%;
  font-family: Barlow;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333333;
  vertical-align: middle;
}

.form-control::placeholder {
  opacity: 0.5;
  font-family: Barlow;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333333;
}
