@import '../../helpers/style/variables';

.teamWrapper {
  margin-top: 24px;
}

.teamGroupWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (min-width: 992px) {
  .teamGroupWrapper {
    flex-direction: row;
  }
}

// Edit button

.editButtonWrapper {
  width: 310px;
  margin-left: auto;
  margin-right: auto;
}

.editButton {
  border-radius: 20px;
  width: 140px;
  margin-bottom: 12px;
}

.editButton > svg {
  width: 32px;
  height: 32px;
}

.editButton:first-child {
  margin-left: 10px;
  margin-right: 10px;
}

.editButton:last-child {
  margin-right: 10px;
}

.editButton:hover {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
}

.editButton > * {
  pointer-events: none;
}

.editButton,
.editButton:hover,
.editButton:focus {
  background-color: transparent;
  color: $main-font-color-light-bg;
}
.editButton.editButtonEditing,
.editButton.editButtonEditing:hover,
.editButton.editButtonEditing:focus {
  background-color: #596c80;
  color: $main-font-color-dark-bg;
}

.editButton:disabled {
  cursor: default;
  background-color: rbga(#cecece, 0.5);
  opacity: 0.5;
}

.editButton:disabled:hover {
  box-shadow: none;
}

@media (min-width: 992px) {
  .editButtonWrapper {
    width: auto;
  }
}
