@import 'variables';

// Bootstrap overrrides

$success: $main-success;
$warning: $main-warning;
$danger: $main-danger;
$primary: $main-orange;
$secondary: $main-blue;
$font-family-sans-serif: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
