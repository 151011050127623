@import '../../helpers/style/variables';
@import '../../helpers/style/lightBgDefaults.scss';

$input-border-radius: 6px; // Boostrap Typeahead CSS

.container {
    display: flex;
}

.color {
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 15em;
    border-radius: 8px !important;

}

.results {
    margin-top: 2em;
}

.filters {
    margin-bottom: 1em;
}

.button {
    margin-top: 0.5em;
    width: 9em;

}
.container-full {
    margin: 0 auto;
    width: 100%;
    background-color:$fill-brand-blue-9

}
.mainContentWrapper {
    margin-top: 3rem;
}

.rectangle {
    background-color:$fill-brand-blue-9

}

.pageTitle  {
    margin-bottom: 1rem;
}


.autocompleteRow > *{
    justify-content: center;
}
@media (max-width: 765px) {
    .autocompleteRow {
        flex-direction: column;
    }
}


@media screen and (max-width: 767px) {
    .container {
        display: block;
    }

    .button {
        margin-top: unset;
    }
}

