@import '../../helpers/style/variables';

.add_comment_btn {
  border: 0 !important;
  color: rgb(139, 139, 139) !important;
  cursor: pointer;
  border-radius: 0 20px 20px 0 !important;
  border-color: black;
  background-color: $secondary-background-color !important;
  position: relative;
  width: 42px;
  padding: 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.send_arrow {
  opacity: 0.5;
}

.type_cmt {
  border: 0 !important;
  margin-left: 12px;
  border-radius: 20px 0px 0px 20px !important;
  overflow-y: auto;
  background-color: $secondary-background-color !important;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
textarea::placeholder {
  color: rgba(62, 83, 104, 0.4) !important;
}

.type_cmt:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.parent_size {
  overflow: hidden;
  position: relative;
  width: 100%;
  justify-content: center;
}
.div_ex_plus {
  align-items: center;
  display: flex;
}

.show_events {
  padding: 0px;
  padding-top: 10px;
}

textarea {
  resize: none;
}

div.cardFooter {
  background-color: $fill-bw-gray-light-01;
  padding-left: 11px !important;
  border-top: 0px !important;
}

.formWrapper {
  position: fixed;
  bottom: 0em;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.eventsOpen {
  margin-top: 273px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
  border-radius: .5rem;
}
