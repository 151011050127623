@import '../../helpers/style/custom.scss';
@import '../../helpers/style/variables';

.padContainers {
  padding-left: 3%;
  padding-right: 3%;
}

.results {
  background-color: $fill-bw-gray-light-00;
  margin-top: 2em;
}

.fieldsContainer {
  background-color: rgba(170, 182, 194, 0.2);
  min-height: 170px;
}

.pageTitle {
  height: 36px;
  font-family: Barlow;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $fill-brand-blue-1;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.geoSwitch {
  height: 30px;
  padding-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.autocompleteRow {
  flex: 1 0 10px;
  display: flex;

  justify-content: space-between;
  align-content: center;
}

@media (max-width: 765px) {
  .autocompleteRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.autocompleteRow div:nth-child(2) {
  margin-left: 2%;
  margin-right: 2%;
}

.numberResults {
  height: 36px;

  font-family: Barlow;

  font-size: 24px;

  font-weight: bold;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.5;

  letter-spacing: normal;

  color: var(--fill-brand-blue-1);
  margin-top: 16px;
}