@import '../../helpers/style/variables';

.loadingIndicatorContainer {
  position: fixed;
  height: 36px;
  width: 100%;
  background-color: $main-background-dark;
  color: $main-font-color-dark-bg;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.loadingIndicatorContainer > p {
  margin: 0 8px 0 0;
}

.spinner {
  width: 16px;
  height: 16px;
}
