@import '../../helpers/style/variables';

// Match Wrapper

.matchWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

.headerWrapper {
  background-color: $fill-brand-blue-2;
}

@media (min-width: 992px) {
  .matchWrapper {
    max-width: 960px;
  }
}
