@import '../../helpers/style/variables';

// No players placeholder

.noPlayersPlaceholder {
  background-color: $main-background-light;
  color: $main-font-color-light-bg;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noPlayersPlaceholder > span {
  text-align: center;
  white-space: 'nowrap';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  font-weight: bold;
  opacity: 0.5;
}
