@import '../../helpers/style/variables';

.notification {
  width: 304px;
  background-color: $main-background-light;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px 8px 0 0;
}

.notification:not(:first-child) {
  margin-top: 12px;
}

.notificationHeader {
  background-color: $main-background-dark;
  color: $main-font-color-dark-bg;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.notificationHeader.error {
  background-color: $main-danger;
}

.notificationHeader.offline {
  background-color: $main-success;
}

.notificationHeader > button {
  color: $main-font-color-dark-bg;
  background-color: transparent;
  border: none;
  font-weight: 700;
  text-shadow: none;
  padding: 0 4px;
  margin-left: 8px;
}

.notificationHeader > button > svg {
  pointer-events: none;
}

.notificationHeader > strong {
  margin-right: auto;
}

.notificationHeader > button:hover {
  color: $main-font-color-dark-bg;
}

.notificationBody {
  color: $main-font-color-light-bg;
  padding: 12px;
  font-size: 14px;
  overflow-y: scroll;
  overflow-wrap: break-word;
}
